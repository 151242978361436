import { logEvent as logEventAmplitude } from '@amplitude/analytics-browser';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '@/context/useAuth';
import {
	addProductToChef,
	deleteProductFromChef,
	getProductsByChef,
} from '@/service/api/chef';
import { IProductLink, getAllProductLinks } from '@/service/api/productLink';

const ManageChefProductsPage = () => {
	const { user } = useAuth();

	const [chefProducts, setChefProducts] = useState<IProductLink[]>([]);
	const [allProducts, setAllProducts] = useState<IProductLink[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<IProductLink | null>(
		null,
	);
	const [chefId, setChefId] = useState<number | null>(null);

	useEffect(() => {
		if (user?.chef) {
			setChefId(user.chef.id);
		}
	}, [user]);

	const fetchChefProducts = async () => {
		if (!chefId) return;
		try {
			const data = await getProductsByChef(chefId);
			setChefProducts(data);
		} catch (error) {
			toast.error('Failed to fetch chef products');
		}
	};

	const fetchAllProducts = async () => {
		try {
			const data = await getAllProductLinks();
			setAllProducts(data);
		} catch (error) {
			toast.error('Failed to fetch all products');
		}
	};

	useEffect(() => {
		if (chefId) {
			fetchChefProducts();
			fetchAllProducts();
		}
	}, [chefId]);

	const handleAddProduct = async () => {
		if (selectedProduct && chefId) {
			// Check if product is already in chef's products
			if (chefProducts.some((product) => product.id === selectedProduct.id)) {
				toast.error('This product is already in your list');
				return;
			}

			// Check if chef has reached the maximum limit of 5 products
			if (chefProducts.length >= 5) {
				toast.error('You can only add up to 5 products');
				return;
			}

			try {
				await addProductToChef(chefId, selectedProduct.id);

				logEventAmplitude('chef_product_added', {
					chef_id: chefId,
					chef_name: user?.name,
					product_id: selectedProduct.id,
					product_name: selectedProduct.name,
				});

				toast.success('Product added successfully');
				fetchChefProducts();
				setSelectedProduct(null);
			} catch (error) {
				toast.error('Failed to add product');
			}
		}
	};

	const handleDeleteProduct = async (productId: number) => {
		if (!chefId) return;
		if (window.confirm('Are you sure you want to delete this product?')) {
			try {
				await deleteProductFromChef(chefId, productId);
				toast.success('Product deleted successfully');
				fetchChefProducts();
			} catch (error) {
				toast.error('Failed to delete product');
			}
		}
	};

	if (!user?.chef) {
		return null;
	}

	return (
		<div className="p-6">
			<h1 className="text-2xl font-bold mb-6">Manage Chef Products</h1>

			{/* Product Selection Methods */}
			<div className="mb-6">
				<h2 className="text-lg font-semibold mb-3">Select a Product to Add</h2>
				<p className="text-sm text-gray-600 mb-3">
					You can add up to 5 products ({chefProducts.length}/5 added)
				</p>

				{/* Dropdown Selection */}
				<div className="mb-4">
					<select
						onChange={(e) => {
							const productId = e.target.value;
							const product = allProducts.find((p) => p.id === +productId);
							setSelectedProduct(product || null);
						}}
						value={selectedProduct?.id || ''}
						className="w-full md:w-96 border rounded-md px-3 py-2"
					>
						<option value="">Select a product by name...</option>
						{allProducts
							.filter(
								(product) => !chefProducts.some((cp) => cp.id === product.id),
							)
							.map((product) => (
								<option key={product.id} value={product.id}>
									{product.name}
								</option>
							))}
					</select>
				</div>

				{/* Visual Grid Selection */}
				<div className="relative">
					<div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-white to-transparent z-10 pointer-events-none" />
					<div className="overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
						<div className="flex gap-4 pb-4 min-w-max">
							{allProducts
								.filter(
									(product) => !chefProducts.some((cp) => cp.id === product.id),
								)
								.map((product) => (
									<div
										key={product.id}
										onClick={() => setSelectedProduct(product)}
										className={`cursor-pointer p-4 border rounded-lg hover:border-brandRed transition-colors flex-shrink-0 w-48 ${
											selectedProduct?.id === product.id
												? 'border-brandRed bg-red-50'
												: 'border-gray-200'
										}`}
									>
										<img
											src={product.thumbnailUrl}
											alt={product.name}
											className="w-full h-32 object-contain mb-2"
										/>
										<p className="text-center text-sm font-medium truncate">
											{product.name}
										</p>
									</div>
								))}
						</div>
					</div>
				</div>

				{/* Add Product Button */}
				<button
					onClick={handleAddProduct}
					disabled={!selectedProduct || chefProducts.length >= 5}
					className="mt-4 bg-brandRed text-white px-6 py-2 rounded-md hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
				>
					Add Selected Product
				</button>
			</div>

			{/* Products Table */}
			<div className="overflow-x-auto">
				<table className="min-w-full bg-white rounded-lg overflow-hidden shadow-md">
					<thead className="bg-gray-100">
						<tr>
							<th className="px-6 py-3 text-left">Product Name</th>
							<th className="px-6 py-3 text-left">Image</th>
							<th className="px-6 py-3 text-left">Link</th>
							<th className="px-6 py-3 text-left">Delete</th>
						</tr>
					</thead>
					<tbody>
						{chefProducts.map((product) => (
							<tr key={product.id} className="border-t">
								<td className="px-6 py-4 w-1/4">{product.name}</td>
								<td className="px-6 py-4 w-1/4">
									<img
										src={product.thumbnailUrl}
										alt={product.name}
										className="w-52 h-52 object-contain"
									/>
								</td>
								<td>
									<a
										href={product.affiliateUrl}
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-600 w-1/6 hover:text-blue-800"
									>
										View Product
									</a>
								</td>
								<td className="px-6 py-4 w-1/6">
									<button
										onClick={() => handleDeleteProduct(product.id)}
										className="text-red-600  hover:text-red-800"
									>
										Delete
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ManageChefProductsPage;
