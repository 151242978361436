import { useState } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import pauseIcon from '@/assets/svg/pause-icon.svg';
import playIcon from '@/assets/svg/play-icon.svg';
import { InstagramPost } from '@/interface/instagram.interfaces';

interface InstagramPendingPostProps {
	post: InstagramPost;
	onSelectPost: (post: InstagramPost, isSelected: boolean) => void;
	isSelected: boolean;
}

const renderMedia = (
	mediaType: string,
	mediaUrl: string,
	play: boolean,
	handlePlayToggle: () => void,
) => {
	if (mediaType === 'IMAGE' || mediaType === 'CAROUSEL_ALBUM') {
		return (
			<img
				src={mediaUrl}
				alt="instagram_image"
				className="w-[300px] min-[430px]:w-96 h-[500px] rounded-3xl object-cover"
			/>
		);
	} else if (mediaType === 'VIDEO') {
		return (
			<div className="relative">
				<div className="rounded-3xl overflow-hidden w-[300px] min-[430px]:w-96">
					<ReactPlayer
						url={mediaUrl}
						playing={play}
						loop={play}
						width="100%"
						height="500px"
						style={{ objectFit: 'cover' }}
						muted
						playsinline
						className="video-player"
						controls={false}
					/>
				</div>
				<button
					onClick={handlePlayToggle}
					className="flex items-center justify-center absolute bottom-4 right-4 h-[40px] w-[40px] bg-white rounded-full shadow-black shadow-inner hover:bg-slate-200"
				>
					<img
						src={play ? pauseIcon : playIcon}
						alt={play ? 'pause-icon' : 'play-icon'}
					/>
				</button>
			</div>
		);
	}
	return null;
};

export const InstagramPendingPost = ({
	post,
	onSelectPost,
	isSelected,
}: InstagramPendingPostProps) => {
	const [play, setPlay] = useState(false);
	const handlePlayToggle = () => setPlay(!play);

	return (
		<div
			data-cy="instagram-pending-post"
			className="flex flex-col items-center w-[300px] min-[430px]:w-96 h-[730px] gap-4"
		>
			<div
				className="relative cursor-pointer"
				onClick={() => onSelectPost(post, !isSelected)}
			>
				<div className="absolute top-4 right-4 z-10">
					<input
						type="checkbox"
						checked={isSelected}
						onChange={(e) => e.stopPropagation()}
						className="w-5 h-5 accent-brandRed cursor-pointer"
					/>
				</div>
				{renderMedia(post.media_type, post.media_url, play, handlePlayToggle)}
			</div>

			<div className="flex flex-col gap-y-6">
				<p className="line-clamp-4 px-4">{post.caption}</p>
				<Link to={post.permalink} className="text-brandRed place-self-center">
					Link to the original post
				</Link>
			</div>
		</div>
	);
};
