import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { useAuth } from '@/context/useAuth';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { useSetInstagramToken } from '@/hooks/chef/useSetInstagramToken';

export function InstagramCallback() {
	const { user, fetchUser, loading: authLoading } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const onCloseModal = () => setOpen(false);

	const [instagramCode] = useState(() => {
		const params = new URLSearchParams(location.search);
		return params.get('code');
	});

	const chefId = user?.chef?.id;

	const {
		isSuccess,
		isError,
		error: setTokenError,
		isLoading,
	} = useSetInstagramToken(chefId, instagramCode);

	useEffect(() => {
		if (authLoading) return;

		if (!instagramCode || !chefId) {
			notifyError('Instagram authorization code not received');
			navigate('/chef/profile');
		}
	}, [instagramCode, chefId, navigate, authLoading]);

	useEffect(() => {
		if (isLoading || isError) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [isLoading, isError]);

	useEffect(() => {
		if (isSuccess) {
			fetchUser();
			notifySuccess;
			navigate('/chef/profile/instagram-posts');
		}
	}, [isSuccess, fetchUser, navigate]);

	useEffect(() => {
		if (isError) {
			const timer = setTimeout(() => {
				navigate('/chef/profile');
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [isError, navigate]);

	if (authLoading) {
		return (
			<div className="flex items-center justify-center min-h-screen">
				<Loader size={1.5} />
			</div>
		);
	}

	return (
		<div className="flex items-center justify-center min-h-screen">
			<PopUp open={open} onClose={onCloseModal} topCss="20vh">
				{!isError && (
					<div className="flex flex-col items-center justify-center w-80 h-60">
						<Loader size={1.5} />
						<p className="text-center mt-2">
							Connecting your Instagram account...
						</p>
					</div>
				)}
				{isError && (
					<div className="flex flex-col items-center justify-center w-80 h-60">
						<p className="text-xl text-center text-alertRed mb-4">
							Failed to connect Instagram account:
						</p>
						<p className="text-center text-alertRed">
							{setTokenError?.message}
						</p>
						<p className="text-sm text-gray-500 mt-4">
							Redirecting in a few seconds...
						</p>
					</div>
				)}
			</PopUp>
		</div>
	);
}
