import { logEvent as logEventAmplitude } from '@amplitude/analytics-browser';

import { Chef } from '@/context/interfaces';
import { IProductLink } from '@/service/api/productLink';

interface IChefStorefrontProps {
	chef: Chef;
	products: IProductLink[];
}

export const ChefStorefront = ({ chef, products }: IChefStorefrontProps) => {
	if (products.length === 0) return null;

	const getAmazonAffiliateUrl = (url: string) => {
		const tag =
			`?tag=${chef.user.name}-${chef.user.last_name}-kitched-22`.toLowerCase();
		return `${url}${tag}`;
	};

	const handleProductClick = (product: IProductLink) => {
		logEventAmplitude('storefront_product_click', {
			productId: product.id,
			productName: product.name,
			chefId: chef.id,
			chefName: chef.user.name,
		});
		window.open(product.affiliateUrl, '_blank');
	};

	return (
		<div className="w-full px-4">
			<h2 className="text-2xl sm:text-3xl md:text-4xl text-center font-semibold md:text-left 2xl:text-5xl tracking-[-0.04em]">
				<span className={`text-brandRed`}>{chef.user.name}'s </span>
				Cookware
			</h2>
			<div className="max-w-7xl pt-10 mx-auto">
				<div className="grid grid-cols-2 sm:grid-cols-none sm:flex sm:flex-row sm:justify-center gap-4 pb-4">
					{products.map((product) => (
						<div key={product.id} className="w-full sm:w-[250px] gap-4">
							<a
								href={getAmazonAffiliateUrl(product.affiliateUrl)}
								target="_blank"
								rel="noopener noreferrer"
								className="block bg-white rounded-lg overflow-hidden hover:shadow-lg hover:border-2 hover:border-brandRed h-full"
							>
								<div className="relative w-full pt-[75%]">
									<img
										src={product.thumbnailUrl}
										alt={product.name}
										className="absolute top-0 left-0 w-full h-full object-contain"
									/>
								</div>
								<div className="p-4">
									<h3 className="text-lg font-semibold mb-2 line-clamp-2 min-h-[3.5rem]">
										{product.name}
									</h3>
									<button
										className="w-full bg-brandRed text-white py-2 px-4 rounded-full hover:bg-red-600 transition-colors"
										onClick={(e) => {
											e.preventDefault();
											handleProductClick(product);
										}}
									>
										View Product
									</button>
								</div>
							</a>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
