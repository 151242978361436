import { CollectionHomeSection } from '@/components/home/CollectionsHomeSection';
import { TrendingOnKitchedSection } from '@/components/home/TrendingOnKitchedSection';
import { ScrollToTop } from '@/helper/scrollToTop';

export default function Home() {
	return (
		<div className="flex flex-col items-center justify-center gap-y-1">
			<ScrollToTop />
			{/* {hasPreferences ? (
				<RecipesForYouByPreferences
					userHasSubscription={fakeUserHasSubscription}
				/>
			) : (
				<RecipesForYou userHasSubscription={fakeUserHasSubscription} />
			)} */}
			<TrendingOnKitchedSection />
			<CollectionHomeSection />
		</div>
	);
}
