import { useState } from 'react';

import arrowLeftIcon from '@/assets/svg/arrowleft-icon.svg';
import arrowRightIcon from '@/assets/svg/arrowright-icon.svg';
import instagramColorfulIcon from '@/assets/svg/colorful/instagram.svg';
import { InstagramPendingPost } from '@/components/chef/chef-profile/InstagramPendingPost';
import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { Title } from '@/components/common/Title/Title';
import { useAuth } from '@/context/useAuth';
import { notifyError } from '@/helper/notifications';
import { useFetchInstagramPosts } from '@/hooks/chef/useFetchInstagramPosts';
import { useGptInstagramPostCheck } from '@/hooks/chef/useGptInstagramPostCheck';
import { useCreateRecipe } from '@/hooks/recipes/useCreateRecipe';
import {
	InstagramPageDirection,
	InstagramPost,
} from '@/interface/instagram.interfaces';
import { IRecipe } from '@/interface/recipe.interfaces';
import { getInstagramCode } from '@/service/api/instagram';

export const InstagramPendingPostsPage = () => {
	const { user } = useAuth();
	const {
		posts,
		isLoadingInstagramPosts,
		isErrorInstagramPosts,
		refetchInstagramPosts,
		setDirection,
		direction,
	} = useFetchInstagramPosts(user?.chef?.id as number);
	const { gtpInstagramPostCheckMutation } = useGptInstagramPostCheck();
	const { createRecipeMutation } = useCreateRecipe();
	const [selectedPosts, setSelectedPosts] = useState<InstagramPost[]>([]);
	const [convertedRecipes, setConvertedRecipes] = useState<IRecipe[]>([]);
	const [failedCount, setFailedCount] = useState(0);
	const [isConverting, setIsConverting] = useState(false);

	const chefId = user?.chef?.id as number;

	const onNavigateToInstagramAuthorizationWindow = async (): Promise<void> => {
		const code = await getInstagramCode();

		window.location.href = code;
	};

	const onGtpInstagramPostCheckMutation = async (
		posts: InstagramPost[],
	): Promise<void> => {
		setIsConverting(true);
		setConvertedRecipes([]);
		setFailedCount(0);

		try {
			for (const post of posts) {
				const result = await gtpInstagramPostCheckMutation.mutateAsync(post);
				if (result) {
					setConvertedRecipes((prev) => [...prev, result]);
					await createRecipeMutation.mutateAsync({
						...result,
						chef: chefId,
					});
				} else {
					setFailedCount((prev) => prev + 1);
					notifyError(
						`Failed to convert post: ${post.caption.slice(0, 30)}...`,
					);
				}
			}
		} finally {
			setIsConverting(false);
			setSelectedPosts([]);
		}
	};

	const handlePostSelection = (post: InstagramPost, isSelected: boolean) => {
		if (isSelected) {
			setSelectedPosts((prev) => [...prev, post]);
		} else {
			setSelectedPosts((prev) => prev.filter((p) => p.id !== post.id));
		}
	};

	const goToNextPage = (): void => {
		setDirection(InstagramPageDirection.NEXT);
		if (direction === InstagramPageDirection.NEXT) {
			refetchInstagramPosts();
		}
	};

	const goToPreviousPage = (): void => {
		setDirection(InstagramPageDirection.PREVIOUS);
		if (direction === InstagramPageDirection.PREVIOUS) {
			refetchInstagramPosts();
		}
	};

	if (isLoadingInstagramPosts) {
		return (
			<div className="flex justify-center items-center h-[30vh]">
				<Loader size={4} />
			</div>
		);
	}

	if (posts?.data?.length === 0 || isErrorInstagramPosts) {
		return (
			<div className="flex flex-col justify-center items-center h-[30vh]">
				<span>No pending posts yet</span>
				<button
					data-cy="connect-with-instagram-button"
					onClick={onNavigateToInstagramAuthorizationWindow}
					className="flex items-center gap-x-2 py-2 px-4 border-2 text-pink-500 border-pink-500 rounded-3xl bg-pink-200"
				>
					<span className=" font-bold">Connect with Instagram</span>
					<img
						src={instagramColorfulIcon}
						alt="instagram-icon"
						className="w-6"
					/>
				</button>
			</div>
		);
	}

	return (
		<div>
			<div className="text-center sm:text-left">
				<Title
					textTitle="Your recipes on "
					colorText="pink-500"
					titleWithColor="Instagram"
				/>
				<p className="text-gray-600 mt-2">
					Click on any post to select it. You can select multiple posts to
					convert them into Kitched recipes at once.
				</p>
			</div>

			{selectedPosts.length > 0 && (
				<div className="fixed bottom-6 right-6 z-10">
					<button
						onClick={() => onGtpInstagramPostCheckMutation(selectedPosts)}
						disabled={isConverting}
						className={`py-3 px-6 text-white rounded-xl bg-brandRed transition-colors hover:bg-[#E1B864] ${
							isConverting ? 'opacity-50 cursor-not-allowed' : ''
						}`}
					>
						{isConverting ? (
							<span className="flex items-center gap-2">
								<Loader size={1} /> Converting {selectedPosts.length} posts...
							</span>
						) : (
							`Convert ${selectedPosts.length} selected posts`
						)}
					</button>
				</div>
			)}

			{convertedRecipes.length > 0 && (
				<div className="fixed top-6 right-6 z-10 bg-green-100 p-4 rounded-xl shadow-lg">
					<p className="text-green-700">
						Successfully converted {convertedRecipes.length} recipes!
						{failedCount > 0 && ` (${failedCount} posts failed to convert)`}
					</p>
				</div>
			)}

			<div className="grid min-[880px]:grid-cols-2 xl:grid-cols-3 place-items-center gap-y-10 mt-10">
				{posts?.data?.map((post) => (
					<div
						key={post.id}
						className={`transition-transform hover:scale-[1.02] ${
							selectedPosts.some((p) => p.id === post.id)
								? 'ring-2 ring-brandRed rounded-3xl'
								: ''
						}`}
					>
						<InstagramPendingPost
							post={post}
							onSelectPost={handlePostSelection}
							isSelected={selectedPosts.some((p) => p.id === post.id)}
						/>
					</div>
				))}
			</div>

			<section className="flex items-center justify-center gap-[6px] mx-auto mt-10 mb-10 h-[52px]">
				{posts?.previous && (
					<button
						onClick={goToPreviousPage}
						className={`w-[52px] h-[52px] bg-buttonGrey rounded-full flex items-center justify-center hover:bg-gray-100`}
					>
						<img src={arrowLeftIcon} alt="arrow-icon" className="w-4 h-4" />
					</button>
				)}
				{posts?.next && (
					<button
						onClick={goToNextPage}
						className={`w-[52px] h-[52px] bg-brandRed rounded-full flex items-center justify-center hover:bg-[#E1B864]`}
					>
						<img src={arrowRightIcon} alt="arrow-icon" className="w-4 h-4" />
					</button>
				)}
			</section>

			{isConverting && (
				<PopUp open={true} onClose={() => void 0} topCss="25vh">
					<div className="flex flex-col gap-y-2 p-10 items-center text-slate-600 font-semibold">
						<Loader size={1.6} />
						<p>Kitched is cooking...</p>
						<p>and converting your posts</p>
						<p className="text-sm text-gray-500">
							{convertedRecipes.length} of {selectedPosts.length} completed
							{failedCount > 0 && ` (${failedCount} failed)`}
						</p>
					</div>
				</PopUp>
			)}
		</div>
	);
};
